import * as React from "react";
import { Box } from "@mui/material";
import Title from "./Title";
import Header from "./Header";
import Footer from "./Footer";
import Experience from "./Experience";
import AboutMe from "./AboutMe";
import banner from "../images/aboutme banner.svg";
import { useTheme } from "@mui/material/styles";

export default function Homepage(props) {
  const theme = useTheme();

  const [isSun, setIsSun] = React.useState(true);
  const resume =
    "https://drive.google.com/file/d/1i6_BGT7kexG3T4p-2tgvyuXPCCdVyWkn/view?usp=sharing";

  const handleScrollAbout = () => {
    const aboutMeElement = document.getElementById("about-me");

    if (aboutMeElement) {
      aboutMeElement.scrollIntoView({
        behavior: "smooth",
        block: "start", // You can adjust this based on your needs (e.g., 'start', 'center', 'end')
      });
    }
  };

  const handleScrollHome = () => {
    const aboutMeElement = document.getElementById("home");

    if (aboutMeElement) {
      aboutMeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleScrollExperience = () => {
    const aboutMeElement = document.getElementById("experience");

    if (aboutMeElement) {
      aboutMeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleScrollExperienceButtons = (name) => {
    const aboutMeElement = document.getElementById(name);

    if (aboutMeElement) {
      aboutMeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const [hebAnchorEl, setHEBAnchorEl] = React.useState(null);
  const hebOpen = Boolean(hebAnchorEl);
  const handleHEBClick = (event) => {
    hebOpen
      ? handleScrollExperience()
      : handleScrollExperienceButtons("heb-info");
    if (hebOpen) {
      setHEBAnchorEl(null);
    } else {
      setHEBAnchorEl(event.currentTarget);
      setTransferAnchorEl(null);
      setAPLAnchorEl(null);
      setHudlAnchorEl(null);
    }
  };

  const [transferAnchorEl, setTransferAnchorEl] = React.useState(null);
  const transferOpen = Boolean(transferAnchorEl);
  const handleTransferClick = (event) => {
    transferOpen
      ? handleScrollExperience()
      : handleScrollExperienceButtons("transfer-info");
    if (transferOpen) {
      setTransferAnchorEl(null);
    } else {
      setTransferAnchorEl(event.currentTarget);
      setHEBAnchorEl(null);
      setAPLAnchorEl(null);
      setHudlAnchorEl(null);
    }
  };

  const [aplAnchorEl, setAPLAnchorEl] = React.useState(null);
  const aplOpen = Boolean(aplAnchorEl);
  const handleAPLClick = (event) => {
    aplOpen
      ? handleScrollExperience()
      : handleScrollExperienceButtons("apl-info");
    if (aplOpen) {
      setAPLAnchorEl(null);
    } else {
      setAPLAnchorEl(event.currentTarget);
      setHEBAnchorEl(null);
      setTransferAnchorEl(null);
      setHudlAnchorEl(null);
    }
  };

  const [hudlAnchorEl, setHudlAnchorEl] = React.useState(null);
  const hudlOpen = Boolean(hudlAnchorEl);
  const handleHudlClick = (event) => {
    hudlOpen
      ? handleScrollExperience()
      : handleScrollExperienceButtons("hudl-info");
    if (hudlOpen) {
      setHudlAnchorEl(null);
    } else {
      setHudlAnchorEl(event.currentTarget);
      setAPLAnchorEl(null);
      setHEBAnchorEl(null);
      setTransferAnchorEl(null);
    }
  };

  return (
    <div>
      <div
        id="home"
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          backgroundColor: isSun ? theme.palette.primary.main : "#001F54",
          animation: isSun ? "backgroundsun 1.5s linear" : "none",
        }}
      >
        <Box
          sx={{
            width: { xs: theme.width.xs, md: theme.width.md },
            maxWidth: { xs: theme.width.xs, md: theme.width.md },
            margin: "auto",
          }}
        >
          <Header
            handleScrollAbout={handleScrollAbout}
            handleScrollExperience={handleScrollExperience}
            resume={resume}
          ></Header>
          <Title
            handleScroll={handleScrollExperience}
            isSun={isSun}
            setIsSun={setIsSun}
          ></Title>
        </Box>
      </div>
      <div
        id="experience"
        style={{ backgroundColor: theme.palette.primary.main }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "auto",
            paddingTop: "30px",
            paddingBottom: "30px",
            textAlign: "center",
          }}
        >
          <Experience
            handleHEBClick={handleHEBClick}
            hebOpen={hebOpen}
            hebAnchorEl={hebAnchorEl}
            handleTransferClick={handleTransferClick}
            transferOpen={transferOpen}
            transferAnchorEl={transferAnchorEl}
            handleAPLClick={handleAPLClick}
            aplOpen={aplOpen}
            aplAnchorEl={aplAnchorEl}
            handleHudlClick={handleHudlClick}
            hudlOpen={hudlOpen}
            hudlAnchorEl={hudlAnchorEl}
          ></Experience>
        </Box>
      </div>
      <div
        id="about-me"
        style={{ backgroundColor: theme.palette.primary.main }}
      >
        <Box
          sx={{
            width: { xs: theme.width.xs, md: theme.width.md },
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "30px",
            textAlign: "center",
          }}
        >
          <AboutMe></AboutMe>
        </Box>
        <img src={banner} alt="banner" style={{ width: "100%" }}></img>
      </div>
      <div
        style={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            width: { xs: theme.width.xs, md: theme.width.md },
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Footer
            resume={resume}
            handleScrollAbout={handleScrollAbout}
            handleScrollHome={handleScrollHome}
            handleScrollExperience={handleScrollExperience}
          ></Footer>
        </Box>
      </div>
    </div>
  );
}

//
